import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, share } from 'rxjs/operators';
import { ApplicationContextItem } from '../../../models/application-context-item';
import { SearchCriteriaValues, SearchStatus } from '../../../models/search-criteria-values';
import { SearchItem } from '../../../models/search-item';
import { SettingsService } from '../../settings.service';
import { LookupService } from '../lookup.service';
import { NoopLookupService } from '../noop-lookup.service';

@Injectable()
export class LookupContextService {
    /**
     * The lookup service provider.
     */
    private currentLookupService: LookupService;
    private currentApplicationContext: ApplicationContextItem;
    /**
     * The current search criteria properties;
     */
    private currentSearchItem: SearchItem;
    /**
     * The current search criteria values, as set by a user.
     */
    private currentSearchCriteria: SearchCriteriaValues;
    private searchCompleteSubject: BehaviorSubject<SearchCriteriaValues> = new BehaviorSubject<SearchCriteriaValues>({
        searchStatus: SearchStatus.Idle,
    } as SearchCriteriaValues);
    private applicationContextItemSubject: BehaviorSubject<ApplicationContextItem> = new BehaviorSubject<ApplicationContextItem>(null);
    private environmentSubscription: Subscription;

    public searchComplete: Observable<SearchCriteriaValues> = this.searchCompleteSubject.asObservable();
    public isValidService = true;
    public currentApplicationContextItem: Observable<ApplicationContextItem> = this.applicationContextItemSubject.asObservable();

    constructor(private settingsService: SettingsService, private injector: Injector, private activatedRoute: ActivatedRoute) {
        this.environmentSubscription = this.settingsService.$environmentSubject.subscribe((env) => {
            if (this.currentSearchCriteria) {
                this.currentSearchCriteria.environment = env;
            }
        });
    }

    public set applicationContext(value: ApplicationContextItem) {
        this.init(value).pipe(first()).subscribe();
    }

    public get applicationContext(): ApplicationContextItem {
        return this.currentApplicationContext;
    }

    public set searchItem(value: SearchItem) {
        this.currentSearchItem = value;
    }

    public get searchItem(): SearchItem {
        return this.currentSearchItem;
    }

    public get searchCriteria(): SearchCriteriaValues {
        return this.currentSearchCriteria;
    }

    public init(value: ApplicationContextItem, snapshot?: ActivatedRouteSnapshot): Observable<SearchCriteriaValues> {
        if (this.currentApplicationContext === value && this.currentSearchCriteria && this.currentSearchCriteria.searchStatus !== SearchStatus.Idle) {
            return of(null);
        }

        if (this.currentApplicationContext !== value) {
            this.currentSearchItem = null;
            this.currentSearchCriteria = null;
        }

        this.currentApplicationContext = value;
        this.applicationContextItemSubject.next(value);

        if (value) {
            const token = this.getInjectionToken(this.currentApplicationContext.lookupService);
            if (token) {
                this.currentLookupService = this.injector.get(token) as LookupService;

                if (!(this.currentLookupService instanceof NoopLookupService)) {
                    // Remove any prior data and reset any states, if applicable.
                    this.currentLookupService.reset();
                    this.isValidService = true;
                    return this.processLookup(snapshot);
                }
            }

            this.isValidService = false;
        }

        return of(null);
    }

    /**
     * Invokes the filter lookup process.
     */
    public performSearch(userInvoked: boolean): Observable<SearchCriteriaValues> {
        if (this.currentLookupService) {
            if (!this.currentSearchCriteria.environment) {
                this.currentSearchCriteria.environment = this.settingsService.environment;
            }

            const tempSearchCriteria = new SearchCriteriaValues();
            tempSearchCriteria.searchStatus = SearchStatus.SearchInProgress;
            tempSearchCriteria.environment = this.currentSearchCriteria.environment;
            tempSearchCriteria.userInvoked = this.currentSearchCriteria.userInvoked;
            tempSearchCriteria.method = this.currentSearchCriteria.method;
            tempSearchCriteria.wasGeneratedFromParams = this.currentSearchCriteria.wasGeneratedFromParams;

            this.currentSearchItem.identifiers.forEach((x) => {
                tempSearchCriteria[x.value] = this.currentSearchCriteria[x.value];
            });

            this.currentSearchCriteria = tempSearchCriteria;
            this.searchCompleteSubject.next(tempSearchCriteria);
            const lookupProcess = this.currentLookupService.performLookup(this.currentSearchCriteria).pipe(
                map((searchCriteria) => {
                    searchCriteria.userInvoked = userInvoked;
                    this.currentSearchCriteria = searchCriteria;
                    this.searchCompleteSubject.next(searchCriteria);
                    return searchCriteria;
                }),
                catchError((error) => {
                    let searchCriteria = this.currentSearchCriteria;
                    if (!searchCriteria) {
                        searchCriteria = new SearchCriteriaValues();
                        this.currentSearchCriteria = searchCriteria;
                    }

                    searchCriteria.searchStatus = SearchStatus.SearchFailed;
                    this.searchCompleteSubject.next(searchCriteria);
                    return of(this.currentSearchCriteria);
                }),
                share()
            );

            return lookupProcess;
        }

        return of(null);
    }

    /**
     * Transforms generic urls to a fully populated version.
     * @param link
     */
    public transformLink(link: string) {
        if (this.currentLookupService) {
            return this.currentLookupService.convertLink(link, this.currentSearchCriteria);
        }

        return link;
    }

    /**
     * Populates the placeholders in the given query parameters
     * @param queryParams
     */
    public populateQueryParams(queryParams: Params): Params {
        if (this.currentLookupService) {
            return this.currentLookupService.populateQueryParams(queryParams, this.currentSearchCriteria);
        }

        return queryParams;
    }

    /**
     * This processes any URL based lookup.
     */
    private processLookup(currentSnapshot?: ActivatedRouteSnapshot): Observable<SearchCriteriaValues> {
        if (!currentSnapshot && this.activatedRoute.component && !this.activatedRoute.parent) {
            const lowestActivatedRoute = this.findLowestDecendent(this.activatedRoute.firstChild);
            if (lowestActivatedRoute) {
                currentSnapshot = lowestActivatedRoute.snapshot;
            }
        }

        if (currentSnapshot) {
            this.currentSearchCriteria = this.currentLookupService.produceSearchCriteriaValues(currentSnapshot);
            const environment: string = this.currentSearchCriteria.environment;
            if (environment) {
                let searchMethod = this.currentSearchCriteria.method;
                if (searchMethod) {
                    searchMethod = searchMethod.toLowerCase();
                }

                this.currentSearchItem = this.currentApplicationContext.primarySearchTypes.find((searchType) => {
                    if (!searchType.method) {
                        return;
                    }

                    const methodName = searchType.method.toLowerCase();

                    return methodName === searchMethod;
                });
                this.settingsService.environment = environment;
                return this.performSearch(false);
            }
        }

        this.currentSearchCriteria = new SearchCriteriaValues();
        this.currentSearchCriteria.searchStatus = SearchStatus.Idle;
        this.currentSearchCriteria.environment = this.settingsService.environment;
        if (this.currentSearchItem) {
            this.currentSearchCriteria.method = this.currentSearchItem.method;
        }

        return of(this.currentSearchCriteria).pipe(
            map((searchCriteria) => {
                this.currentSearchCriteria = searchCriteria;
                this.searchCompleteSubject.next(searchCriteria);
                return searchCriteria;
            })
        );
    }

    private findLowestDecendent(currentSnapshot: ActivatedRoute): ActivatedRoute {
        if (currentSnapshot != null) {
            if (currentSnapshot.firstChild != null) {
                let result = this.findLowestDecendent(currentSnapshot.firstChild);
                if (!result) {
                    result = currentSnapshot.firstChild;
                }

                return result;
            }
        }

        return currentSnapshot;
    }

    /**
     * Retrieves the proper token for finding the proper Lookup instance.
     * @param lookupServiceReferece Name or Token of the Lookup class.
     */
    private getInjectionToken(lookupServiceReference: string | InjectionToken<LookupService>): InjectionToken<LookupService> {
        let token: InjectionToken<LookupService>;
        if (!lookupServiceReference) {
            throw new TypeError('No value provided for lookup service!');
        }

        if (typeof lookupServiceReference === 'string') {
            // This method is no longer preferrable and may be deprecated.
            // Please populate your ApplicationContextItem.lookupService property with an InjectionToken instance.
            /// If this isn't possible, then proceed to use this method as a stop gap until a permanent solution can be
            // implemented.
            /*
            lookupServiceReferece = lookupServiceReferece.toUpperCase();
            if (lookupServiceReferece === 'MODERNCOMMERCELOOKUPSERVICE') {
                token = ModernCommerceLookupToken;
            } else if (lookupServiceReferece === 'ENTERPRISELOOKUPSERVICE') {
                token = EnterpriseLookupToken;
            } else {
                throw new ErrorEvent(`No token found for ${lookupServiceName}`);
            }
            */
        } else {
            token = lookupServiceReference;
        }

        return token;
    }
}
