export enum ConsumerPurchaseRouteParamsEnum {
    UserId = 'userid',
    OrderId = 'orderid',
    AccountId = 'accountid',
    BillingRecordId = 'billingrecordid',
    BillingApiVersion = 'billingapiversion',
}

export type ConsumerPurchaseSubpathBasesType = {
    OrderStorage: string;
    OrderEvents: string;
    BillingRecords: string;
    PromoCode: string;
    RequestTelemetryLogs: string;
};
export const ConsumerPurchaseSubpathBases: ConsumerPurchaseSubpathBasesType = {
    OrderStorage: 'getorderstoragedocument',
    OrderEvents: 'getorderevents',
    BillingRecords: 'getbillingrecords',
    PromoCode: 'getpromocodedetails',
    RequestTelemetryLogs: 'getpurchaserequesttelemetrylogs',
};

export type ConsumerPurchaseSubpathType = {
    HomeComponent: string;
    OrderStorage: string;
    OrderStorageWithParams: string;
    OrderEvents: string;
    OrderEventsWithParams: string;
    BillingRecords: string;
    BillingRecordsWithAccountParam: string;
    BillingRecordsWithAccountAndBillingRecordParams: string;
    BillingRecordsWithAllParams: string;
    PromoCode: string;
    RequestTelemetryLogs: string;
};
export const ConsumerPurchaseSubpath: ConsumerPurchaseSubpathType = {
    HomeComponent: '',
    OrderStorageWithParams: `${ConsumerPurchaseSubpathBases.OrderStorage}/:${ConsumerPurchaseRouteParamsEnum.UserId}/:${ConsumerPurchaseRouteParamsEnum.OrderId}`,
    OrderStorage: `${ConsumerPurchaseSubpathBases.OrderStorage}`,
    OrderEventsWithParams: `${ConsumerPurchaseSubpathBases.OrderEvents}/:${ConsumerPurchaseRouteParamsEnum.UserId}/:${ConsumerPurchaseRouteParamsEnum.OrderId}`,
    OrderEvents: `${ConsumerPurchaseSubpathBases.OrderEvents}`,
    BillingRecordsWithAllParams: `${ConsumerPurchaseSubpathBases.BillingRecords}/:${ConsumerPurchaseRouteParamsEnum.AccountId}/:${ConsumerPurchaseRouteParamsEnum.BillingRecordId}/:${ConsumerPurchaseRouteParamsEnum.BillingApiVersion}`,
    BillingRecordsWithAccountAndBillingRecordParams: `${ConsumerPurchaseSubpathBases.BillingRecords}/:${ConsumerPurchaseRouteParamsEnum.AccountId}/${ConsumerPurchaseRouteParamsEnum.BillingRecordId}`,
    BillingRecordsWithAccountParam: `${ConsumerPurchaseSubpathBases.BillingRecords}/:${ConsumerPurchaseRouteParamsEnum.AccountId}`,
    BillingRecords: `${ConsumerPurchaseSubpathBases.BillingRecords}`,
    PromoCode: `${ConsumerPurchaseSubpathBases.PromoCode}`,
    RequestTelemetryLogs: `${ConsumerPurchaseSubpathBases.RequestTelemetryLogs}`,
};
