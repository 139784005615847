import { RootPath } from '../app-routing/root-paths';
import { ConsumerSubpath } from '../consumer/consumer-subpaths';
import { EnterpriseTenant_OrgId_Subpath, EnterpriseTenant_Subpath } from '../enterprise/enterprise-subpaths';
import { DrillDownConfigBag } from '../shared-components/drill-down-viewer/models/drill-down-config-bag';
import { LinkableIdType } from '../shared-components/drill-down-viewer/models/schemas/drill-down-property-schema';

/**
 * Generates a URL of the specified linkableIdType.
 * The given globalVariables must contain a defined $$environment.
 * Also, if the specified linkableIdType is EnterpriseOrgId, globalVariables must contain a defined $$tenantId.
 * @param linkableIdType
 * @param id
 * @param globalVariables
 */
export function generateUrl(linkableIdType: LinkableIdType, id: string, globalVariables: DrillDownConfigBag): string {
    const environment: string = globalVariables['$$environment'];
    switch (linkableIdType) {
        case LinkableIdType.ConsumerPuid:
            return generateConsumerUrl(ConsumerSubpath.AccountProfile, environment, id);
        case LinkableIdType.EnterpriseTenantId:
            return generateEnterpriseTenantUrl(EnterpriseTenant_Subpath.AccountProfile, environment, id);
        case LinkableIdType.EnterpriseOrgId:
            // Here we are assuming that the given orgId is related to the current context's tenantId
            const tenantId: string = globalVariables['$$tenantId'];
            const orgId = id;
            return generateEnterpriseTenantOrgIdUrl(EnterpriseTenant_OrgId_Subpath.AccountProfile, environment, tenantId, orgId);
    }
}

export function generateConsumerUrl(path: ConsumerSubpath, environment: string, puid: string): string {
    return `#/${RootPath.Consumer}/${path}`.replace(':environment', environment).replace(':puid', puid);
}

export function generateEnterpriseTenantUrl(path: EnterpriseTenant_Subpath, environment: string, tenantId: string): string {
    return `#/${RootPath.EnterpriseLegacy}/${path}`.replace(':environment', environment).replace(':tenantId', tenantId);
}

export function generateEnterpriseTenantOrgIdUrl(path: EnterpriseTenant_OrgId_Subpath, environment: string, tenantId: string, orgId: string): string {
    return `#/${RootPath.EnterpriseLegacy}/${path}`
        .replace(':environment', environment)
        .replace(':tenantId', tenantId)
        .replace(':organizationId', encodeURIComponent(orgId));
}
