import { Location } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { DrillDownConfig } from '../models/drill-down-config';
import { DrillDownFieldPathElement } from '../models/drill-down-field-path-element';
import { DrillDownNavState } from '../models/drill-down-nav-state';
import { NavStateInitializerResponse } from '../models/nav-state-initializer-response';
import { DrillDownArraySchema } from '../models/schemas/drill-down-array-schema';
import { DrillDownObjectSchema } from '../models/schemas/drill-down-object-schema';
import { DrillDownPropertySchema } from '../models/schemas/drill-down-property-schema';
import { WindowInjectionTokenForDD } from '../models/window-proxy';
import { DrillDownDeferredDataProviderService } from './drill-down-deferred-data-provider.service';
import { adjustNavState, isEquivalentParams, setFieldPath, setTabName } from './drill-down-state-service-functions';
import { DrillDownStateServiceInitializer } from './drill-down-state-service-initializer';

/**
 * This service maintains the state of the DrillDownViewer.
 * Currently, it tracks the navigational state for the app.
 */
@Injectable()
export class DrillDownStateService {
    private dataStates = new Array<DrillDownNavState>();
    private stateKeeper = new BehaviorSubject<DrillDownNavState>(null);
    private queryTrack = false;
    private currentParameters: Params;
    private currentUrl: string;
    public config: DrillDownConfig;

    constructor(
        private router: Router,
        public injector: Injector,
        private route: ActivatedRoute,
        private location: Location,
        @Inject(WindowInjectionTokenForDD) private window: Window,
        private drillDownDeferredDataProviderService: DrillDownDeferredDataProviderService
    ) {
        this.router.events.subscribe((x) => {
            if (x instanceof NavigationEnd) {
                if (this.dataStates && this.dataStates.length > 0) {
                    const rootState = this.dataStates[0];
                    if (rootState) {
                        const data = rootState.originalMenu;
                        const schema = rootState.originalMenuSchema;
                        const config = this.config;
                        const name = rootState.propertyName;
                        this.processQueryParameters(data, schema, config, name).pipe(first()).subscribe();
                    }
                }
            }
        });
    }

    /**
     * Used to get the latest navigation state.
     */
    public currentNavState = this.stateKeeper.asObservable();

    public init(
        data: any,
        schema: DrillDownArraySchema | DrillDownObjectSchema,
        config: DrillDownConfig,
        name: string
    ): Observable<NavStateInitializerResponse> {
        this.config = config;
        this.drillDownDeferredDataProviderService.init();

        if (config) {
            this.queryTrack = config.enableUrlTracking;
            if (this.queryTrack === undefined || this.queryTrack === null) {
                this.queryTrack = false;
            }
        }

        return this.processQueryParameters(data, schema, config, name);
    }

    /**
     * Sets the current navigation state.  If the navigation state isn't found,
     * the request is ignored and the function will return null.
     * @param state - The desired state.
     */
    public setExistingNavState(state: DrillDownNavState): DrillDownNavState {
        const index = this.dataStates.findIndex((x) => x === state);
        const length = this.dataStates.length;

        // Removes all navigation states that exists after a user-selected point.
        // The last navigation state isn't eligible, since there isn't anything else after it.
        if (index > -1 && length - 1 > index) {
            this.dataStates.splice(index + 1, length - index);
            state.fieldPath = []; // The field path isn't applicable here.
            this.stateKeeper.next(state);

            this.refreshUrl();
            return state;
        } else {
            return null;
        }
    }

    /**
     * Sets the tab name for the current state.
     * @param tabName The name of the current tab.
     */
    public setTabNameForCurrentState(tabName: string): void {
        if (setTabName(this.dataStates, tabName)) {
            this.refreshUrl();
        }
    }

    /**
     * Sets the fieldPath for the current Drill Down Nav State
     * @param fieldPath The lineage path.
     */
    public setFieldPathForCurrentState(fieldPath: Array<DrillDownFieldPathElement>): void {
        if (setFieldPath(this.dataStates, fieldPath)) {
            this.refreshUrl();
        }
    }

    /**
     * Sets the current navigation state.  If the navigation state isn't found,
     * a new DrillDownNavState will be created and propagated throughout the application.
     * @param dataSelected The data, namely the array element from the menuValues paramenter, selected.
     * @param propertyName The name of the property in which this data came from.
     * @param menuValues The array in which the dataSelected parameter was selected.
     * @param identifierSchema The property schema of the identifier field.
     * @param objectSchema The schema of the current dataSelected object.
     * @param menuSchema The schema of the property in which the menuValues parameter was associated with.
     * @param depth The depth from the root object that is currently being displayed on the DrillDownViewer.
     * @param tabName The name of the tab or viewer that's rendering this data.
     */
    public setNewNavState(
        dataSelected: any,
        propertyName: string,
        menuValues: Array<any>,
        identifierSchema?: DrillDownPropertySchema,
        objectSchema?: DrillDownObjectSchema,
        menuSchema?: DrillDownArraySchema,
        depth?: number,
        tabName?: string,
        fieldPath?: Array<DrillDownFieldPathElement>,
        updateState: boolean = true
    ): DrillDownNavState {
        const navState: DrillDownNavState = adjustNavState(
            this.dataStates,
            dataSelected,
            propertyName,
            menuValues,
            identifierSchema,
            objectSchema,
            menuSchema,
            depth,
            tabName,
            fieldPath,
            this.config ? this.config.globalVariables : null
        );

        if (updateState) {
            this.stateKeeper.next(navState);

            this.refreshUrl();
        }

        return navState;
    }

    /**
     * Removes the current state, in favor it's closest ancestor's state.
     */
    public pop(): DrillDownNavState {
        if (this.dataStates.length > 1) {
            return this.dataStates.pop();
        }

        return null;
    }

    /**
     * Set the Navigation service to the root state.
     */
    public setToRoot(): DrillDownNavState {
        if (this.dataStates.length > 0) {
            const state = this.dataStates[0];
            return this.setExistingNavState(state);
        }

        return null;
    }

    /**
     * Retrieves an immutable rendition of an array of navigation states.
     * @returns {DrillDownNavState} An immutable rendition of an array of navigation states
     */
    public get allNavStates(): ReadonlyArray<DrillDownNavState> {
        return this.dataStates;
    }

    public generatePermalink(): string {
        const params = this.generateParamsFromNavStates();
        const urlTree = this.router.createUrlTree(['.'], { queryParams: params, relativeTo: this.route });
        return this.window.location.origin + this.window.location.pathname + this.location.prepareExternalUrl(this.router.serializeUrl(urlTree));
    }

    private processQueryParameters(
        data: any,
        schema: DrillDownArraySchema | DrillDownObjectSchema,
        config: DrillDownConfig,
        name: string,
        params?: Params
    ): Observable<NavStateInitializerResponse> {
        params = params ? params : Object.assign({}, this.route.snapshot.queryParams);
        let rootData = null;
        if (this.allNavStates.length > 0) {
            const firstElement = this.allNavStates[0];
            if (firstElement) {
                if (data === firstElement.originalMenu) {
                    rootData = firstElement.originalMenu;
                } else if (data) {
                    // This is ensures that the new data won't overridden.
                    params = {};
                }
            }
        }

        if (!this.currentParameters || data !== rootData || !isEquivalentParams(params, this.currentParameters)) {
            this.currentParameters = Object.assign({}, params); // making a copy, since the other one will be altered.
            const initializer = new DrillDownStateServiceInitializer(data, schema, config, name, params, this.drillDownDeferredDataProviderService);
            return initializer.processRequest().pipe(
                catchError((exception) => {
                    return of([]);
                }),
                map((states) => {
                    if (states && states.length > 0 && !initializer.errorMessage) {
                        this.dataStates = states;
                        this.stateKeeper.next(states[states.length - 1]);
                        return { isValid: true };
                    } else {
                        return {
                            isValid: false,
                            errorMessage: initializer.errorMessage,
                        };
                    }
                })
            );
        } else {
            return of({ isValid: true });
        }
    }

    private refreshUrl() {
        if (this.queryTrack) {
            const params = this.generateParamsFromNavStates();
            const url = this.router.createUrlTree(['.'], { queryParams: params, relativeTo: this.route }).toString();
            if (url !== this.currentUrl) {
                this.currentUrl = url;
                this.router.navigate(['.'], { queryParams: params, relativeTo: this.route });
            }
        }
    }

    private generateParamsFromNavStates(): Params {
        const results: Params = {};

        if (this.dataStates) {
            const length = this.dataStates.length;
            const lastItemIndex = length - 1;
            if (length > 1) {
                this.dataStates.forEach((dataElement, index) => {
                    if (index > 0) {
                        if (dataElement.id !== null && dataElement.id !== undefined) {
                            results[`Lvl${index}.id`] = dataElement.id;
                        }

                        results[`Lvl${index}.name`] = dataElement.propertyName ? dataElement.propertyName : 'Element';
                        if (dataElement.fieldPath && index !== lastItemIndex) {
                            const fieldPathSequence = dataElement.fieldPath.map((x) => (x.propertyName ? x.propertyName.trim() : null)).filter((x) => x);
                            if (fieldPathSequence.length > 0) {
                                results[`Lvl${index}.fieldPath`] = dataElement.fieldPath.map((x) => x.propertyName).join(',');
                            }
                        }

                        if (dataElement.tabName) {
                            results[`Lvl${index}.tab`] = dataElement.tabName;
                        }
                    } else {
                        results[`Lvl${index}.name`] = dataElement.propertyName ? dataElement.propertyName : 'Root';
                    }
                });
            }
        }

        return results;
    }
}
